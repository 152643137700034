import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';

import { CanComponentDeactivate } from '../../../guards/can-deactivate.guard';
import { Router } from '@angular/router';

import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';

import { Subscription, Observable, fromEvent } from 'rxjs';

import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DbService } from '@services/core/db.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TicketsModalComponent } from '../lobby/tickets-modal/tickets-modal.component';
import { first } from 'rxjs/operators';
import { TicketService } from '@services/other/ticket.service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  @ViewChild("endCallTemplate", { static: true }) private endCallTemplate: TemplateRef<any>;
  @ViewChild("otErrorTemplate", { static: true }) private otErrorTemplate: TemplateRef<any>;

  unreadDot: boolean = false;

  sidebarOpen: boolean = true;

  private callStateSub: Subscription = null;
  private modalRef: BsModalRef; 
  beforeUnloadSub: Subscription = null;

  boxHeight = 380;
  boxWidth = 567;

  iframeWorkflowData = null;

  isSubmitted = false;

  connectedSub: Subscription = null
  connectedTimeout = null

  subscriptions: Subscription[] = [];
  attachedTicketId

  mandatoryTicketSub

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    public callService: CallService,
    private roomSessionService: RoomSessionService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private router: Router,
    private translateService: TranslateService,
    private modal: BsModalService,
    public sanitizer: DomSanitizer,
    private ticketService: TicketService
  ) {
    if (!this.callService.inRoom) {
      this.router.navigate(['/']);
    }

    this.callService.on("endCall", this.onEndCall);
    this.callService.on("otSessionError", this.onOtSessionError);
    this.callService.on("otSubscriberError", this.onOtSubscriberError);
    this.callService.on("otPublisherError", this.onOtPublisherError);
  }

  ngOnInit() {
    if (environment.production) {
      this.beforeUnloadSub = fromEvent(window, "beforeunload").subscribe(e => { e.returnValue = true; });
    }

    if (window.innerWidth < 768) {
      this.sidebarOpen = false;
    }

    const roomId = this.roomSessionService.currentRoomId
    const sessionId = this.roomSessionService.currentSessionId
    if (roomId && sessionId) {
      this.subscriptions.push(this.authService.addOns.subscribe(addOns => {
        if(addOns.mandatoryticket && this.authService.currentUser.role === "expert") {
          this.mandatoryTicketSub = this.ticketService.mandatoryTicketSource.next(true)
          this.onTicketModal()
        }
      }))

      this.connectedSub = this.dbService.isConnected.subscribe(isConnected => {
        if (!isConnected) {
          this.connectedTimeout = setTimeout(() => this.callService.simulateEndcallForFrontend(roomId, sessionId, "frontend/no-internet-connection"), 3000);
        } else {
          if (this.connectedTimeout) {
            clearTimeout(this.connectedTimeout);
            this.connectedTimeout = null;
          }
        }
      });
    }
  }

  onTicketModal(){
    this.subscriptions.push(this.roomSessionService.sessionData.pipe(first()).subscribe(sessionData => {
      this.attachedTicketId = sessionData.attached_ticket;
      if(!this.attachedTicketId ) {
        this.subscriptions.push(this.roomSessionService.currentRoom.pipe(first()).subscribe(currentRoom => {
          const initialState= {
            currentRoom,
            title: this.translateService.instant(`APP.MAIN.LOBBY.TICKETS.CHOOSE_TICKET`),
            disableClose: true
          };
         this.modalRef = this.modal.show(TicketsModalComponent,  {initialState, backdrop:'static', class: 'white modal-lg', animated:false, keyboard:false});
       }))
      }
    }));
  }

  onEndCall = (roomId: string, sessionId: string, kickRoomStatus: string, reconnectionRequired: boolean, sessionEnded: boolean) => {
    if (reconnectionRequired) {
      this.router.navigate(['/reconnect'], { state: {roomId: roomId, sessionId: sessionId, kickRoomStatus, reconnectionRequired, sessionEnded: sessionEnded } });
    } else {
      if (this.authService.currentUser.role === "expert" && this.authService.currentUser.session_rating && this.authService.currentUser.session_rating.count === 0) {
        // Navigate to session rating first
        this.router.navigate(['/session-rating'], { state: {roomId: roomId, sessionId: sessionId, sessionEnded: sessionEnded } });
      } else {
        // Navigate to lobby
        this.router.navigate(['/']);
      }
    }
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  onOtSessionError = (name: string, room_id: string, code: number) => {
    const dataModel = { room_id: room_id, action: "rejoin", error_key: `APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.UNKNOWN`, code: code };
    switch (name) {
      case "OT_NOT_CONNECTED":
      case "OT_CONNECT_FAILED":
        dataModel.error_key = "APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.INTERNET";
        break;
      case "OT_AUTHENTICATION_ERROR":
        dataModel.error_key = "APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.TRY_AGAIN";
        break;
      default:
        dataModel.error_key = "APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.UNKNOWN";
        break;
    }
    this.showOtErrorModal(dataModel);
/*
    'OT_AUTHENTICATION_ERROR'
    'OT_BADLY_FORMED_RESPONSE'
    'OT_CONNECT_FAILED'
    'OT_CONNECTION_LIMIT_EXCEEDED'
    'OT_EMPTY_RESPONSE_BODY'
    'OT_INVALID_SESSION_ID'
    'OT_INVALID_PARAMETER'
    'OT_NOT_CONNECTED'
    'OT_TERMS_OF_SERVICE_FAILURE'
    'OT_INVALID_HTTP_STATUS'
    'OT_XDOMAIN_OR_PARSING_ERROR'
*/
  }

  onOtSubscriberError = (name: string, room_id: string, code: number) => {
    const dataModel = { room_id: room_id, action: "rejoin", error_key: `APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.SUBSCRIBER_FAILED`, code: code };
    this.showOtErrorModal(dataModel);
/*
    'OT_CREATE_PEER_CONNECTION_FAILED'
    'OT_DISCONNECTED'
    'OT_INVALID_PARAMETER'
    'OT_ICE_WORKFLOW_FAILED'
    'OT_MEDIA_ERR_ABORTED'
    'OT_MEDIA_ERR_DECODE'
    'OT_MEDIA_ERR_NETWORK'
    'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'
    'OT_NOT_CONNECTED'
    'OT_SET_REMOTE_DESCRIPTION_FAILED'
    'OT_STREAM_DESTROYED'
    'OT_STREAM_LIMIT_EXCEEDED'
    'OT_STREAM_NOT_FOUND'
*/
  }

  onOtPublisherError = (name: string, room_id: string, code: number) => {
    console.log(name);
    if (name !== "OT_NO_DEVICES_FOUND") {
      const dataModel: any = { room_id: room_id, action: "rejoin", error_key: `APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.PUBLISHER_FAILED`, code: code };
  
      if (name === "OT_CONSTRAINTS_NOT_SATISFIED" || name === "OT_HARDWARE_UNAVAILABLE" || name === "OT_USER_MEDIA_ACCESS_DENIED") {
        dataModel.action = "endcall";
        dataModel.error_key2 = "APP.MAIN.ROOM.OT_ERROR_MODAL.ERRORS.PUBLISHER_CAM_MIC_ACCESS";
      }
      this.showOtErrorModal(dataModel);
    }
/*
    'OT_CHROME_MICROPHONE_ACQUISITION_ERROR'
    'OT_CONSTRAINTS_NOT_SATISFIED'
    'OT_CREATE_PEER_CONNECTION_FAILED'
    'OT_HARDWARE_UNAVAILABLE'
    'OT_ICE_WORKFLOW_FAILED'
    'OT_INVALID_PARAMETER'
    'OT_MEDIA_ERR_ABORTED'
    'OT_MEDIA_ERR_DECODE'
    'OT_MEDIA_ERR_NETWORK'
    'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'
    'OT_NO_DEVICES_FOUND'
    'OT_NO_VALID_CONSTRAINTS'
    'OT_NOT_CONNECTED'
    'OT_NOT_SUPPORTED'
    'OT_PERMISSION_DENIED'
    'OT_SCREEN_SHARING_NOT_SUPPORTED'
    'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED'
    'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED'
    'OT_SET_REMOTE_DESCRIPTION_FAILED'
    'OT_STREAM_CREATE_FAILED'
    'OT_TIMEOUT'
    'OT_USER_MEDIA_ACCESS_DENIED'
    'OT_UNEXPECTED_SERVER_RESPONSE'
*/
/*
    'OT_HARDWARE_UNAVAILABLE'
    'OT_INVALID_PARAMETER'
    'OT_MEDIA_ERR_ABORTED'
    'OT_MEDIA_ERR_DECODE'
    'OT_MEDIA_ERR_NETWORK'
    'OT_MEDIA_ERR_SRC_NOT_SUPPORTED'
    'OT_NOT_SUPPORTED'
    'OT_NO_DEVICES_FOUND'
    'OT_NO_VALID_CONSTRAINTS'
    'OT_SCREEN_SHARING_NOT_SUPPORTED'
    'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED'
    'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED'
*/
  }

  showOtErrorModal(model: any) {
    const roomId = this.callService.currentRoomId;
    const sessionId = this.roomSessionService.currentSessionId;
    const modalId = this.modalService.show({
      template: this.otErrorTemplate,
      context: {
        dataModel: model,
        callbacks: {
          close: () => this.modalService.hide(modalId),
          rejoin: () => {
            this.modalService.hide(modalId);
            this.callService.simulateEndcallForFrontend(roomId, sessionId, "frontend/ot-connection-problem")
          },
          endcall: () => {
            this.loaderService.show();
            this.callService.endCall()
            .then(() => this.modalService.hide(modalId))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe subscriptions before component destroyed
    if (this.callStateSub) { this.callStateSub.unsubscribe() }
    if (this.beforeUnloadSub) { this.beforeUnloadSub.unsubscribe() }
    if (this.connectedSub) { this.connectedSub.unsubscribe() }

    if (this.connectedTimeout) { clearTimeout(this.connectedTimeout) }
    this.subscriptions.forEach(s => s.unsubscribe())
    if(this.mandatoryTicketSub) { this.mandatoryTicketSub.unsubscribe() }

    this.callService.off("endCall", this.onEndCall);
    this.callService.off("otSessionError", this.onOtSessionError);
    this.callService.off("otSubscriberError", this.onOtSubscriberError);
    this.callService.off("otPublisherError", this.onOtPublisherError);

    try {
      if (window.top) {
        window.top.postMessage({ closed: true }, "*")
      }
    } catch (error) {
      console.log(error)
    }
  }

  onToggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;

    if (this.sidebarOpen) {
      this.unreadDot = false;
    }
  }

  unreadNewMessage(unread: boolean) {
    if (!this.sidebarOpen) {
      this.unreadDot = true;
    } else {
      this.unreadDot = false;
    }
  }

  openEndCallModal() {
    const modalId = this.modalService.show({
      template: this.endCallTemplate,
      context: {
        dataModel: null,
        callbacks: {
          no: () => {
            this.modalService.hide(modalId);
          },
          yes: () => {
            this.loaderService.show();
            this.modalService.hide(modalId);
            this.callService.endCall()
            .then(() => {
              this.loaderService.hide();
            })
            .catch(error => {
              this.loaderService.hide();
            });
          }
        }
      }
    });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.callService.inRoom) {
      this.openEndCallModal();
    }
    return !this.callService.inRoom;
  }

  workflowDataChanged(workflow: any) {
    this.loaderService.show();
    const encodedToken = encodeURIComponent(btoa(this.authService.currentUser.token))

    this.iframeWorkflowData = {
      // TODO workflow.name should be changed to support content translation
      workflowName: workflow['listName'],
      src: `${environment.endPoints.workflowBase}/show-workflow?id=${workflow.id}&token=${encodedToken}`
    }
    this.loaderService.hide();
  }

  close() {
    this.iframeWorkflowData = null;
  }

/*
  workflowDataChanged(event:Event){
    this.workflow = event
    this.showWorkflow = true
  }

  close() {
    if(this.isSubmitted){
      this.showWorkflow = false
        this.workflow = {}
    }else{
      const initialState= {
        title: this.translateService.instant(`APP.MAIN.WORKFLOW.CLOSE_MODAL.TITLE`),
        description: this.translateService.instant(`APP.MAIN.WORKFLOW.CLOSE_MODAL.MESSAGE`),
        approveTitle: this.translateService.instant(`APP.MAIN.WORKFLOW.CLOSE_MODAL.CLOSE`),
        instantClose:false
      };
      let confirmModalRef = this.modal.show(ConfirmModalComponent,  {initialState, backdrop:'static', class:'modal-dialog-centered', animated:false});
      confirmModalRef.content.onClose.subscribe((res) => {
        if (res == true) {
          confirmModalRef.hide();
          this.showWorkflow = false
          this.workflow = {}
        }
      })
    }
  }

  workflowSubmitted(event:boolean) {
    if(event){
      this.isSubmitted = true
    }
  }
*/
}
