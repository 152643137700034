import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';
import { AuthService } from '@services/core/auth.service';
import { CallService } from '@services/core/call.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { DbService } from '@services/core/db.service';
import { LogService } from '@services/core/log.service';
import { OpentokService } from '@services/core/opentok.service';
import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '@services/support/loader.service';
import { ModalService } from '@services/support/modal.service';
import { UtilityService } from '@services/support/utility.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-platform-login',
  templateUrl: './platform-login.component.html',
  styleUrls: ['./platform-login.component.scss']
})
export class PlatformLoginComponent implements OnInit {

  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild('videoElement') videoElement: ElementRef;
  @ViewChild("checkAgreementModel", {static: true}) private checkAgreementTemplate: TemplateRef<any>;
  @ViewChild("agreementModel", {static: true}) private agreementTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;

  authSubscription: Subscription = null;

  browserParser: any;
  loginInProgress: boolean = false;

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  users: string[]

  roomId: string
  customToken: string

  linkId: string
  guestName: string

  constructor(
    private dbService: DbService,
    private authService: AuthService,
    private callService: CallService,
    private loaderService: LoaderService,
    private flashMessageService: FlashMessageService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    /*** THIS SERVICES INJECTED FOR BOOTSTRAP ***/
    public opentokService: OpentokService,
    private logService: LogService,
    private collaborationService: CollaborationService,
    private arCollaborationService: ArCollaborationService

  ) {
    //document.body.style.backgroundColor = "transparent";
  }

  ngOnInit() {
    try {
      if (window.top) {
        window.top.postMessage({ opened: true }, "*")
      }
    } catch (error) {
      console.log(error)
    }

    this.browserParser = this.utilityService.getBrowserParser();

    // Navigate main if user already authenticated
    this.authSubscription = this.authService.isAuthenticated().subscribe(auth => {
      if (auth) {
        console.log("auth exists")
        this.authService.logout()
      }
    });

    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
    });

    this.authenticate()
  }

  async authenticate() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
    //this.authService.unifiedPanel = true

    const params = await this.route.queryParams.pipe(first()).toPromise();
    if (!(params && params.origin)) {
      return;
    }

    this.origin = decodeURIComponent(params.origin)

    if (params.room && params.token) {
      this.roomId = params.room
      this.customToken = atob(decodeURIComponent(params.token))
      this.onJoin()
    } else if (params.users && params.token) {
      this.users = JSON.parse(decodeURIComponent(params.users))
      this.customToken = atob(decodeURIComponent(params.token))
      this.onJoin()
    } else if (params.linkId && params.name) {
      this.linkId = params.linkId
      this.guestName = decodeURIComponent(params.name)
      this.onJoin()
    }
  }

  origin = null
  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent<any>) {
    if (event.origin === this.origin) {
      //window.top.postMessage("teeest", "*")
    }
  }

  cameraMirrorChanged(mirror: boolean) {
    this.opentokService.changeCameraMirror(mirror);
  }

  onJoin() {
    const isTouchDevice = 'ontouchstart' in window;
    // Is Device iOS or ipadOS (detected as touch macOS)
    const isIOS = this.browserParser.is("iOS");
    const isIpad = this.browserParser.is("macOS") && isTouchDevice;

    if (isIOS || isIpad) {
      if (!this.browserParser.is("Safari")) {
        this.showNotSupportedModal({ ios: true });
        return;
      } else if (!this.browserParser.satisfies({safari: ">=12"})) {
        this.showNotSupportedModal({ ios: true, ios_version_low: true });
        return;
      }
    }
    if (this.browserParser.is("Microsoft Edge") && !this.browserParser.satisfies({edge: '>=79' })) {
      this.showNotSupportedModal({ ios: false });
      return;
    }

    if (!(environment.design.supportedBrowserList.some(browserName => this.browserParser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
      this.showNotSupportedModal({ ios: false });
      return;
    }
    this.showJoinModal();
  }

  showNotSupportedModal(dataModel: any) {
    const modalId = this.modalService.show({
      template: this.notSupportedTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  showJoinModal() {
    // @ts-ignore
    const AContext = window.AudioContext || window.webkitAudioContext;
    const model = {
      publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
      currentStream: null,
      audios: [],
      videos: [],
      selectedVideo: null,
      selectedAudio: null,
      audioEnabled: true,
      videoEnabled: false,
      //@ts-ignore
      audioContext: new AContext(),
      audioLevel: 0,
      audioLevelSub: null,
      settingsOn: false,
      status: "waiting",
      enableJoin: false,
      enableCancel: false
    };
    const modalId = this.modalService.show({
      template: this.joinTemplate,
      context: {
        dataModel: model,
        callbacks: {
          toggleAudio: () => this.opentokService.togglePreviewAudio(model),
          toggleVideo: () => this.opentokService.togglePreviewVideo(model),
          changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
          changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
          close: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);

            window.top.postMessage({ closed: true }, "*")
          },
          join: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);
            if (model.status === 'no-devices-found') {
              this.showNoDeviceModal();
            } else {
              this.authService.addOns.pipe(first(), map(addOns => addOns.agreement)).toPromise()
              .then(showAgreement => {
                if (showAgreement) {
                  this.showAgreement();
                }
              })
              this.join(false);
            }
          }
        }
      }
    });

    setTimeout(() => {
      this.opentokService.startPreview(model, this.videoElement);
    }, 1000);
  }

  showNoDeviceModal() {
    const modalId = this.modalService.show({
      template: this.noDeviceTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
            this.showJoinModal();
          },
          join: () => {
            this.modalService.hide(modalId);
            this.authService.addOns.pipe(first(), map(addOns => addOns.agreement)).toPromise()
            .then(showAgreement => {
              if (showAgreement) {
                this.showAgreement();
              }
            })
            this.join(false);
          }
        }
      }
    });
  }

  join(archiveAllowed: boolean) {
    this.loaderService.show();
    this.loginInProgress = true;

    let promise
    if (this.roomId && this.customToken) {
      promise = this.authService.login(this.customToken)
        .then(() => this.callService.joinRoom(this.roomId, archiveAllowed))
        .then(() => this.router.navigate(['/room']))
    } else if (this.users && this.customToken) {
      promise = this.authService.login(this.customToken)
        .then(() => this.callService.callUsers(this.users, archiveAllowed))
        .then(() => this.router.navigate(['/room']))
        .catch(error => {
          if (error.error === 'call-in-progress') {
            this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CALL_IN_PROGRESS', { timeout: 10000 });
          } else if (error.error === 'concurrent-limit-reached') {
            this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
          } else if (error.error === 'expert-concurrent-limit-reached') {
            this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR', { timeout: 10000 });
          } else if (error.error === 'archive-permission-needed') {
            this.showArchivePermissionModal();
          } else {
            this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_UNKNOwN_ERROR');
          }

          setTimeout(() => {
            if (window.top) {
              window.top.postMessage({ closed: true }, "*")
            }
          }, 1000);
        })
    } else if (this.linkId && this.guestName) {
      promise = this.authService.joinWithLink(this.linkId, this.guestName)
        .then(result => this.authService.loginWithLink(result.token).then(() => result))
        .then(result => this.callService.joinRoom(result.data.room_id, archiveAllowed))
        .then(() => this.router.navigate(['/room']))
    }
    promise.catch(error => {

      if (error.message === 'archive-permission-needed') {
        this.showArchivePermissionModal();
      } else {
        if (error.message === 'session-not-found') {
          //this.error = 'session-not-found';
          this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_FOUND');
        } else if (error.message === 'session-expired') {
          //this.error = 'session-expired';
          this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_AVAILABLE');
        } else if (error.message === 'concurrent-limit-reached') {
          this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.CONCURRENT_LIMIT_REACHED');
        } else if (error.message === 'expert-concurrent-limit-reached') {
          this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.EXPERT_CONCURRENT_LIMIT_REACHED');
        } else if (error.message === 'no-training-license') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.NO_TRAINING_LICENSE');
        } else if (error.message === 'training-license-expired') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.TRAINING_LICENSE_EXPIRED');
        } else if (error.message === 'training-license-error') {
          this.flashMessageService.showTranslated('APP.MAIN.LOBBY.ASK_TRAINING_HOST');
        } else {
          this.flashMessageService.showTranslated('APP.EXTERNALS.JOIN_WITH_LINK.UNKNOWN_ERROR');
        }

        setTimeout(() => {
          if (window.top) {
            window.top.postMessage({ closed: true }, "*")
          }
        }, 1000);
      }
    })
    .finally(() => {
      this.loginInProgress = false;
      this.loaderService.hide();
    });
  }

  showAgreement() {
    const modalId = this.modalService.show({
      template: this.agreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          reject: () => {
            this.showCheckAgreement(modalId);
          },
          accept: () => {
            this.loaderService.show();
            this.authService.acceptAgreement()
            .then(() => {
              this.modalService.hide(modalId);
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.ERROR')) //There is an error occured
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showCheckAgreement(agreementModalId: number) {
    const modalId = this.modalService.show({
      template: this.checkAgreementTemplate,
      context: {
        dataModel: null,
        callbacks: {
          close: () => {
            this.modalService.hide(modalId);
          },
          proceed: () => {
            this.loaderService.show();
            this.logout()
            .then(() => {
              this.modalService.hide(modalId)
              this.modalService.hide(agreementModalId)
            })
            .catch(error => this.flashMessageService.showTranslated('APP.MAIN.NAVBAR.LOGOUT_FAILED'))
            .finally(() => this.loaderService.hide());
          }
        }
      }
    });
  }

  showArchivePermissionModal() {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => this.modalService.hide(modalId),
          allow: () => {
            this.join(true);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  logout(): Promise<void> {
    if (this.callService.inRoom) {
      return this.callService.endCall()
      .then(() => {
        return this.authService.logout();
      });
    } else {
      // For prevent loader flashing
      return new Promise(resolve => setTimeout(resolve, 500))
      .then(() => {
        return this.authService.logout();
      });
    }
  }

  ngOnDestroy() {
    if (this.authSubscription) { this.authSubscription.unsubscribe() }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
  }

}
