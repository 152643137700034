<div class="main-wrapper">
    <app-navbar [openProfileRequest]="openProfileRequest"></app-navbar>
    <router-outlet *ngIf="isUserDataLoaded"></router-outlet>
</div>

<ng-template #sessionExportTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-header px-4">
                <h5 class="modal-title">{{ 'APP.MAIN.GET_EXPORT_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body p-4">
                <div class="mb-3 ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.MESSAGE1' | translate }}</div>
                <div class="mb-3 ml-1" style="font-size: 14px;" [innerHTML]="'APP.MAIN.GET_EXPORT_MODAL.MESSAGE2' | translate"></div>

                <div class="form-group mb-1">
                    <div class="input-group">
                        <input type="text" class="form-control" [class.is-invalid]="dataModel.exportName.length === 0" [disabled]="isExportNameLocked" style="font-size: 14px;"
                            maxlength="72" placeholder="{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_PLACEHOLDER' | translate }}" [(ngModel)]="dataModel.exportName" (ngModelChange)="onExportNameChange($event)">
                        <div class="input-group-append">
                            <span class="input-group-text" style="font-size: 14px; cursor: default;">.zip</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_NOT_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div *ngIf="isExportNameLocked" class="ml-1" style="font-size: 14px;">{{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_LOCKED' | translate }} <span *ngIf="exportNameAuthor"> {{ 'APP.MAIN.GET_EXPORT_MODAL.EXPORT_NAME_RENAMED' | translate }} {{ exportNameAuthor }}</span></div>
                <div class="form-group mt-3 mb-0">
                    <tag-input #tagInput name="emailRecipients" style="font-size: 14px;" [(ngModel)]="dataModel.emailRecipients" placeholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.PLACEHOLDER' | translate }}"
                        secondaryPlaceholder="{{ 'APP.MAIN.EMAIL_RECIPIENTS.SECONDARY_PLACEHOLDER' | translate }}" theme="minimal" [validators]="[validMailValidator]" [errorMessages]="invalidEmailError"
                        [animationDuration]="{enter: '0ms', leave: '0ms'}" [addOnBlur]="true"></tag-input>
                </div>

                <div class="mt-3 ml-1 text-info">
                    <span>{{ (dataModel.ad_user ? 'APP.MAIN.GET_EXPORT_MODAL.NOTE2' : 'APP.MAIN.GET_EXPORT_MODAL.NOTE') | translate }}</span>
                    <a *ngIf="!dataModel.ad_user" (click)="openProfileRequest.next()" class="ml-1" style="cursor: pointer; text-decoration: underline;">{{ 'APP.MAIN.GET_EXPORT_MODAL.OPEN_PROFILE' | translate }}</a>
                </div>
            </div>
            <div class="modal-footer px-4">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.GET_EXPORT_MODAL.CLOSE' | translate }}</button>
                <button class="btn btn-sm btn-info px-4" [disabled]="dataModel.emailRecipients.length === 0 || dataModel.exportName.length === 0" (click)="callbacks.getexport()">{{ 'APP.MAIN.GET_EXPORT_MODAL.SEND' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notSupportedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p *ngIf="!dataModel.ios" class="mx-3">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_MESSAGE' | translate }}</p>
                <ng-container *ngIf="dataModel.ios">
                    <p *ngIf="!dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.NO_IOS_MESSAGE' | translate:('design' | env) }}</p>
                    <p *ngIf="dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.IOS_VERSION_MESSAGE' | translate:('design' | env) }}</p>
                    <div class="d-flex justify-content-center">
                        <a [href]="('design' | env).appStoreLink" target="_blank" rel="noopener">
                            <img class="d-block mx-auto" style="width: 80%; max-width: 200px; object-fit: contain;" src="assets/badges/applestore.png">
                        </a>
                    </div>
                </ng-container>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #joinTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog join-room-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="video-container">
                    
                    <div *ngIf="dataModel.status === 'permission-denied'">
                        <div class="redbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>
                    
                    <div *ngIf="dataModel.status === 'running' && !dataModel.videoEnabled">
                        <div class="blackbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    
                    <div class="video-controls">
                        <a class="control" (enabledClick)="callbacks.toggleVideo();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.videos.length === 0" [ngClass]="{'video-disabled': !dataModel.videoEnabled}">
                            <i class="mi" [ngClass]="{'mi-videocam': dataModel.videoEnabled, 'mi-videocam-off': !dataModel.videoEnabled}"></i>
                        </a>
                        <a class="control ml-2" (enabledClick)="callbacks.toggleAudio();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.audios.length === 0" [ngClass]="{'audio-disabled': !dataModel.audioEnabled}">
                            <div class="audio-level" [style.height.%]="dataModel.audioEnabled ? dataModel.audioLevel : 0"></div>
                            <i class="mi" [ngClass]="{'mi-mic': dataModel.audioEnabled, 'mi-mic-off': !dataModel.audioEnabled}"></i>
                        </a>
                    </div>
                    <div class="video-settings">
                        <a class="control" (enabledClick)="dataModel.settingsOn = !dataModel.settingsOn" [fakeDisabled]="dataModel.status !== 'running'">
                            <i class="mi mi-settings"></i>
                        </a>
                    </div>
                </div>
                <div class="settings-container pt-3 pb-2" [ngClass]="{'show': dataModel.settingsOn}" style="border-bottom: 1px solid #dee2e6;">
                    <form #settingsForm="ngForm">
                        <div class="form-row justify-content-center" *ngIf="('design' | env).showPreferredResolution">
                            <div class="form-group col-11 col-sm-10 mb-1">
                                <label class="mb-1" for="publishResolution">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.PREFERRED_RESOLUTION' | translate }}</label>
                                <ng-select name="publishResolution" [items]="dataModel.publishResolutions" [clearable]="false" [searchable]="false" [dropdownPosition]="'top'"
                                    [(ngModel)]="opentokService.selectedPublishResolution"></ng-select>
                            </div>
                            <div class="text-info col-11 col-sm-10 mb-3">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.RESOLUTION_NOTE' | translate }}</div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-5 mb-2 mb-sm-0">
                                <ng-select [items]="dataModel.videos" [clearable]="false" name="videoSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.videoEnabled"
                                    placeholder="Video Device" [(ngModel)]="dataModel.selectedVideo" (change)="callbacks.changeVideoSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                            <div class="form-group col-11 col-sm-5 mb-0">
                                <ng-select [items]="dataModel.audios" [clearable]="false" name="audioSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.audioEnabled"
                                    placeholder="Audio Device" [(ngModel)]="dataModel.selectedAudio" (change)="callbacks.changeAudioSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-10 mb-0">
                                <div class="custom-control custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" class="custom-control-input" id="mirror" name="mirror" [(ngModel)]="cameraMirror" (ngModelChange)="cameraMirrorChanged($event)">
                                    <label class="custom-control-label" for="mirror">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.MIRROR_VIDEO' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="contacts-container py-2" [ngClass]="{'show': dataModel.callData}">
                    <div class="text-center" [innerHTML]="'APP.MAIN.CALL_MESSAGE.TITLE' | translate:dataModel.callData"></div>
                </div>
                <div class="message-container" [ngSwitch]="dataModel.status">
                    <span *ngSwitchCase="'permission-required'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_REQUIRED' | translate }}</span>
                    <span *ngSwitchCase="'permission-denied'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_DENIED' | translate }}</span>
                    <span *ngSwitchCase="'cant-access-devices'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_CANT_ACCESS' | translate }}</span>
                    <span *ngSwitchCase="'no-devices-found'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_NO_DEVICE' | translate }}</span>
                    <ng-container *ngSwitchCase="'running'">
                        <span *ngIf="!dataModel.audioEnabled && dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_MIC_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.videoEnabled && dataModel.audioEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.audioEnabled && !dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_MIC_OFF' | translate }}</span>    
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dataModel.status === 'permission-denied'" class="prompt-text px-3 py-4">
                <p class="px-1 py-2 mt-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.ALLOW_ACCESS' | translate }}</p>
                <p class="font-weight-bold px-3 py-2 mb-1">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.TRY_AGAIN' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-responsive btn-sm btn-outline-dark px-3" (click)="callbacks.close()" [disabled]="!dataModel.enableCancel">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-responsive btn-sm btn-success px-4" (click)="callbacks.join()" [disabled]="!dataModel.enableJoin">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN' | translate }}</button>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #noDeviceTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN_BODY' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-outline-dark px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CANCEL' | translate }}
                </button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.join()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #archivePermissionModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.deny()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.allow()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.ALLOW' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #whatsNewTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="whatsnew-modal modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <a class="close-carousel bg-white" (click)="callbacks.close()"><i class="mi mi-cancel text-danger"></i></a>
                <carousel [interval]="0">
                    <slide *ngFor="let update of cards; index as i;">
                        <img class="whatsnew-img" *ngIf="update.image" [src]="update.image">
                        <div class="carousel-caption border-top border-muted">
                            <h3 class="carousel-caption-title">{{ update.title }}</h3>
                            <p class="carousel-caption-message pt-1">{{ update.desc }}</p>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #sessionFilesTemplete let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">{{ 'APP.MAIN.LOBBY.SESSIONS.FILES' | translate }}</div>
                <div>
                <button class="btn btn-sm btn-dark px-4 rounded-0" style="color: white; background-color: transparent; border: 1px solid #777;" (click)="callbacks.toggleSelection()">{{ 'APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.MULTIPLE_SELECTION' | translate }}</button>
                &nbsp;
                <button *ngIf="showDownloadButton" class="btn btn-sm btn-dark px-4 rounded-0" style="color: white; background-color: transparent; border: 1px solid #777;"  (click)="callbacks.onDownloadStarted()">{{ 'APP.MAIN.LOBBY.SESSIONS.SESSION_FILES.DOWNLOAD' | translate }}</button>

                </div>
            </div>
            <div class="modal-body">
                <div class="modal-body" style="min-height: 300px; max-height: 300px;">
                    <app-session-file [session]="dataModel.session" [session_id]="dataModel.sessionId" [showCheckboxes]="showCheckboxes" [downloadSelectedFiles]="downloadSelectedFiles"></app-session-file>
                  </div>
                  
            </div>
            <div class="modal-footer d-flex justify-content-between py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">Close</button>
            </div>
        </div>
    </div>
</ng-template>