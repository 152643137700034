<div class="join-link-wrapper">
    <div class="join-link-container">
        <div class="logo-container">
            <img *ngIf="!('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).logoStyle" [src]="('design' | env).logo">
            <img *ngIf="('design' | env).loginLogo" class="logo-image" [ngStyle]="('design' | env).loginLogoStyle" [src]="('design' | env).loginLogo">
            <div *ngIf="('design' | env).logoText" class="logo-text ml-2">{{ ('design' | env).logoText }}</div>
        </div>
        <div class="card" [ngClass]="{'d-none': showDownloadApp}">
            <div class="card-header pt-1 pb-0" [style.background-color]="('design' | env).loginColor"></div>
            <div class="card-body px-2 px-md-5 py-1">
                <div class="loader my-5 mx-auto" style="color: black;" *ngIf="loading"><div></div><div></div><div></div><div></div><div></div></div>
                <ng-container *ngIf="!loading">
                    <div *ngIf="!error" class="join-link-primary-title text-center mt-5">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.TITLE' | translate:('design' | env) }}</div>
                    <div *ngIf="error === 'session-not-found'" class="join-link-primary-title text-center my-5">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_FOUND' | translate }}</div>
                    <div *ngIf="error === 'session-expired'" class="join-link-primary-title text-center my-5">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.SESSION_NOT_AVAILABLE' | translate }}</div>
                    <div *ngIf="error === 'internal-error'" class="join-link-primary-title text-center my-5">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.UNKNOWN_ERROR' | translate }}</div>
                </ng-container>

                <div class="pb-3" [ngClass]="{'d-none': (error || loading) }">
                    <div class="join-link-secondary-title text-center d-flex align-items-center justify-content-center my-3" *ngIf="('design' | env).showCompanyOnGuestAccess">
                        <i class="mi mi-lock" style="font-size: 16px;"></i>
                        <span style="font-size: 16px;line-height: 16px;">{{ linkData?.company_name }}</span>
                    </div>
                    <div class="join-link-secondary-title text-center mt-3 pb-2" *ngIf="linkData && linkData.type !== 'scheduled'">
                        <span>{{ 'APP.EXTERNALS.JOIN_WITH_LINK.ABOUT_TO_JOIN' | translate }}</span><strong>{{ linkData.room_name }}</strong>
                    </div>

                    <div class="mx-3 text-muted" *ngIf="linkData && (linkData.type === 'scheduled' || linkData.type === 'outlook')">
                        <hr>
                        <div class="d-flex flex-row align-items-center mb-2">
                            <i class="mi mi-title mr-2"></i>
                            <div>{{ linkData.room_name }}</div>
                        </div>
                        <div class="d-flex flex-row align-items-center mb-2" *ngIf="linkData.type === 'scheduled'">
                            <i class="mi mi-schedule mr-2"></i>
                            <div>
                                <div class="mb-1">{{ linkData.start | utcCalc:linkData.timezone }} {{ linkData.timezone }}</div>
                                <div>{{ linkData.end | utcCalc:linkData.timezone }} {{ linkData.timezone }}</div>        
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center mb-2" *ngIf="linkData.type === 'scheduled'">
                            <i class="mi mi-timer mr-2"></i>
                            <div>{{ getMeetingDuration(linkData.start, linkData.end) }}</div>
                        </div>
                        <div class="d-flex flex-row align-items-center mb-2">
                            <i class="mi mi-priority-high mr-2" style="opacity: 0; visibility: hidden;"></i>
                            <div class="text-muted" style="font-size: 14px;">If you are the owner of this meeting, login from <a class="login-link" [style.color]="('design' | env).linkColor" routerLink="/login" [queryParams]="{'joinroom': linkData.room_id}">here</a>.</div>
                        </div>
                        <hr>
                    </div>

                    <form #joinForm="ngForm" id="joinForm" (ngSubmit)="onJoin()" class="mt-4 mx-3">
                        <div class="form-group">
                            <input class="field form-control py-2" placeholder="{{ 'APP.EXTERNALS.JOIN_WITH_LINK.ENTER_NAME' | translate }}"
                                #name="ngModel" [(ngModel)]="guestName" [ngClass]="{'is-invalid':name.errors && name.touched}"
                                type="text" name="account" minlength="2" required autocomplete="organization">
                            <small class="invalid-feedback mx-2" [hidden]="!name.errors?.required">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.NAME_REQUIRED' | translate }}</small>
                            <small class="invalid-feedback mx-2" [hidden]="!name.errors?.minlength">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.NAME_SHORT' | translate }}</small>
                        </div>
                    </form>

                    <div class="mx-3" *ngIf="('design' | env).showLoggingInFromChina">
                        <div class="custom-control custom-checkbox d-flex align-items-center">
                            <input type="checkbox" class="custom-control-input" id="chinaserver" name="chinaserver" [(ngModel)]="useChinaServer" (ngModelChange)="chinaServerChange($event)">
                            <label class="custom-control-label" for="chinaserver">{{ 'APP.LOGIN.USE_CHINA_SERVERS' | translate }}</label>
                        </div>
                        <small class="text-danger" style="font-size: 12px;" [hidden]="!useChinaServer">{{ 'APP.LOGIN.CHINA_SERVERS_CAUTION' | translate }}</small>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex flex-row justify-content-between align-items-center" *ngIf="!(error || loading)">
                <!--<div class="language-dropdown" dropdown #dropdown="bs-dropdown">
                    <button id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                        <i class="mi mi-language mr-2"></i>
                        <span>{{ currentLang.code | uppercase }}</span>
                        <i class="mi mi-keyboard-arrow-down ml-2"></i>
                    </button>
                    <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                        <ng-container *ngFor="let lang of allLangs">
                            <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                <a class="dropdown-item language-link px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>-->
                <div class="language-dropdown" dropdown [dropup]="true" #dropdown="bs-dropdown">
                    <button id="language-toggle" class="language-link d-flex flex-row align-items-center" dropdownToggle aria-controls="language-menu">
                        <i class="mi mi-language mr-2"></i>
                        <span>{{ currentLang.code | uppercase }}</span>
                        <i class="mi mi-keyboard-arrow-down ml-2"></i>
                    </button>
                    <ul id="language-menu" *dropdownMenu class="dropdown-menu py-0" role="menu" aria-expanded="false" aria-labelledby="language-toggle">
                        <ng-container *ngFor="let lang of allLangs">
                            <li class="language-item" role="menuitem" *ngIf="currentLang !== lang">
                                <a class="dropdown-item language-link px-2" (click)="onLanguageSelected(lang)">{{ lang.code | uppercase }}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <button form="joinForm" class="btn btn-dark px-4" [disabled]="!joinForm?.valid || loginInProgress">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.JOIN' | translate }}</button>
            </div>
        </div>
        <div class="card" [ngClass]="{'d-none': !showDownloadApp}">
            <div class="card-header pt-1 pb-0"></div>
            <div class="card-body px-2 px-md-5 py-1">
                <div class="d-flex flex-column my-5">
                    <input type="text" class="form-control guest fs-14" style="opacity:0;" [formControl]="publicLink" #guestLink>
                    <div class="text-center">
                        <a class="google-play-link" (click)="clickStoreLink(guestLink)" [href]="('design' | env).googlePlayLink + '&referrer=utm-source%3Dvsight%26utm_medium%3Dguest%26utm_content%3Dwid-' + linkId" target="_blank" rel="noopener"><img src="assets/badges/googleplay.png"></a>
                    </div>
                    <div class="text-center mt-4 mb-2">
                        <a class="apple-store-link" (click)="clickStoreLink(guestLink)" [href]="('design' | env).appStoreLink" target="_blank" rel="noopener"><img src="assets/badges/applestore.png"></a>
                    </div>
                    <div class="text-center mt-4 mb-2">
                        <a class="apple-store-link" (click)="clickStoreLink(guestLink)" [href]="('design' | env).apkLink" target="_blank" rel="noopener"><img src="assets/badges/smartglasses.png"></a>
                    </div>
                    <a class="continue-website-link mt-4" href="#" (click)="showDownloadApp = false" target="_blank" rel="noopener">{{ 'APP.EXTERNALS.JOIN_WITH_LINK.CONTINUE_TO_WEBSITE' | translate }}</a>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!(error || loading || showDownloadApp)">
            <label *ngIf="!('design' | env).unclickableTerms" class="agree-text w-100 mt-2">{{ 'APP.LOGIN.BEFORE_TERMS' | translate }}<ng-container *ngIf="!('design' | env).hideTermsOfService"><a (click)="onTermsofService()" [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.TERMS' | translate }}</a>{{ 'APP.LOGIN.CONJUNCTION' | translate }}</ng-container><a (click)="onPrivacyPolicy()" [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.PRIVACY' | translate }}</a>{{ 'APP.LOGIN.AFTER_PRIVACY' | translate }}</label>
            <label *ngIf="('design' | env).unclickableTerms" class="agree-text w-100 mt-2">{{ 'APP.LOGIN.BEFORE_TERMS' | translate }}<a [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.TERMS' | translate }}</a>{{ 'APP.LOGIN.CONJUNCTION' | translate }}<a [style.color]="('design' | env).linkColor">{{ 'APP.LOGIN.PRIVACY' | translate }}</a>{{ 'APP.LOGIN.AFTER_PRIVACY' | translate }}</label>

            <label class="agree-text w-100 mt-3" *ngIf="('design' | env).showPoweredBy">Powered by <a href="https://vsight.io" target="_blank" [style.color]="('design' | env).linkColor">VSight</a></label>
            <label class="agree-text w-100 mt-3" *ngIf="('design' | env).customPoweredBy">Powered by <a [href]="('design' | env).customPoweredByURL" target="_blank" [style.color]="('design' | env).linkColor">{{ ('design' | env).customPoweredBy }}</a></label>    
        </ng-container>
    </div>
</div>

<ng-template #notSupportedTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p *ngIf="!dataModel.ios" class="mx-3">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_MESSAGE' | translate }}</p>
                <ng-container *ngIf="dataModel.ios">
                    <p *ngIf="!dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.NO_IOS_MESSAGE' | translate:('design' | env) }}</p>
                    <p *ngIf="dataModel.ios_version_low" class="mx-3 mb-3">{{ 'APP.MAIN.LOBBY.IOS_VERSION_MESSAGE' | translate:('design' | env) }}</p>
                    <div class="d-flex justify-content-center">
                        <a [href]="('design' | env).appStoreLink" target="_blank" rel="noopener">
                            <img class="d-block mx-auto" style="width: 80%; max-width: 200px; object-fit: contain;" src="assets/badges/applestore.png">
                        </a>
                    </div>
                </ng-container>
            </div>

            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.NOT_SUPPORTED_CLOSE' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #joinTemplate let-dataModel="dataModel" let-callbacks="callbacks">
    <div class="modal-dialog join-room-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="video-container">

                    <div *ngIf="dataModel.status === 'permission-denied'">
                        <div class="redbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>
                    <video [ngStyle]="{'transform': cameraMirror ? 'scaleX(-1)' : 'scaleX(1)'}" #videoElement autoplay="true" playsinline="true" muted></video>
                    
                    <div *ngIf="dataModel.status === 'running' && !dataModel.videoEnabled">
                        <div class="blackbox d-flex justify-content-center">
                            <i class="mi mi-videocam-off" style="color: white; font-size: 50px; opacity: 0.9;"></i>
                        </div>
                    </div>

                    <div class="video-controls">
                        <a class="control" (enabledClick)="callbacks.toggleVideo();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.videos.length === 0" [ngClass]="{'video-disabled': !dataModel.videoEnabled}">
                            <i class="mi" [ngClass]="{'mi-videocam': dataModel.videoEnabled, 'mi-videocam-off': !dataModel.videoEnabled}"></i>
                        </a>
                        <a class="control ml-2" (enabledClick)="callbacks.toggleAudio();" [fakeDisabled]="dataModel.status !== 'running' || dataModel.audios.length === 0" [ngClass]="{'audio-disabled': !dataModel.audioEnabled}">
                            <div class="audio-level" [style.height.%]="dataModel.audioEnabled ? dataModel.audioLevel : 0"></div>
                            <i class="mi" [ngClass]="{'mi-mic': dataModel.audioEnabled, 'mi-mic-off': !dataModel.audioEnabled}"></i>
                        </a>
                    </div>
                    <div class="video-settings">
                        <a class="control" (enabledClick)="dataModel.settingsOn = !dataModel.settingsOn" [fakeDisabled]="dataModel.status !== 'running'">
                            <i class="mi mi-settings"></i>
                        </a>
                    </div>
                </div>
                <div class="settings-container py-3" [ngClass]="{'show': dataModel.settingsOn}">
                    <form #settingsForm="ngForm">
                        <div class="form-row justify-content-center" *ngIf="('design' | env).showPreferredResolution">
                            <div class="form-group col-11 col-sm-10 mb-1">
                                <label class="mb-1" for="publishResolution">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.PREFERRED_RESOLUTION' | translate }}</label>
                                <ng-select name="publishResolution" [items]="dataModel.publishResolutions" [clearable]="false" [searchable]="false" [dropdownPosition]="'top'"
                                    [(ngModel)]="opentokService.selectedPublishResolution"></ng-select>
                            </div>
                            <div class="text-info col-11 col-sm-10 mb-3">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.RESOLUTION_NOTE' | translate }}</div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-5 mb-2 mb-sm-0">
                                <ng-select [items]="dataModel.videos" [clearable]="false" name="videoSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.videoEnabled"
                                    placeholder="Video Device" [(ngModel)]="dataModel.selectedVideo" (change)="callbacks.changeVideoSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                            <div class="form-group col-11 col-sm-5 mb-0">
                                <ng-select [items]="dataModel.audios" [clearable]="false" name="audioSetting" bindLabel="label" bindValue="deviceId" [searchable]="false" [disabled]="!dataModel.audioEnabled"
                                    placeholder="Audio Device" [(ngModel)]="dataModel.selectedAudio" (change)="callbacks.changeAudioSource($event)" [dropdownPosition]="'top'"></ng-select>
                            </div>
                        </div>
                        <div class="form-row justify-content-center">
                            <div class="form-group col-11 col-sm-10 mb-0">
                                <div class="custom-control custom-checkbox d-flex align-items-center">
                                    <input type="checkbox" class="custom-control-input" id="mirror" name="mirror" [(ngModel)]="cameraMirror" (ngModelChange)="cameraMirrorChanged($event)">
                                    <label class="custom-control-label" for="mirror">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.MIRROR_VIDEO' | translate }}</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="message-container" [ngSwitch]="dataModel.status">
                    <span *ngSwitchCase="'permission-required'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_REQUIRED' | translate }}</span>
                    <span *ngSwitchCase="'permission-denied'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_PERM_DENIED' | translate }}</span>
                    <span *ngSwitchCase="'cant-access-devices'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CAM_MIC_CANT_ACCESS' | translate }}</span>
                    <span *ngSwitchCase="'no-devices-found'" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_NO_DEVICE' | translate }}</span>
                    <ng-container *ngSwitchCase="'running'">
                        <span *ngIf="!dataModel.audioEnabled && dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_MIC_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.videoEnabled && dataModel.audioEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_OFF' | translate }}</span>
                        <span *ngIf="!dataModel.audioEnabled && !dataModel.videoEnabled" class="mt-2 p-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN_CAM_MIC_OFF' | translate }}</span>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="dataModel.status === 'permission-denied'" class="prompt-text px-3 py-4">
                <p class="px-1 py-2 mt-2">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.ALLOW_ACCESS' | translate }}</p>
                <p class="font-weight-bold px-3 py-2 mb-1">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.TRY_AGAIN' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-responsive btn-sm btn-outline-dark px-3" (click)="callbacks.close()" [disabled]="!dataModel.enableCancel">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.CANCEL' | translate }}</button>
                <button class="btn btn-responsive btn-sm btn-dark px-4" (click)="callbacks.join()" [disabled]="!dataModel.enableJoin">{{ 'APP.MAIN.LOBBY.JOIN_MODAL.JOIN' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #noDeviceTemplate let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body px-0 py-4">
                <p class="mx-3">{{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN_BODY' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-outline-dark px-4" (click)="callbacks.cancel()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.CANCEL' | translate }}
                </button>
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.join()">
                    {{ 'APP.MAIN.LOBBY.NO_DEVICE_MODAL.JOIN' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #archivePermissionModal let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                <p>{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.MESSAGE' | translate }}</p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.deny()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.DENY' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.allow()">{{ 'APP.MAIN.LOBBY.ARCHIVE_PERMISSION_MODAL.ALLOW' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #agreementModel let-callbacks="callbacks">
    <div class="modal-dialog modal-dialog-wide" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.TITLE' | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <p class="agreementModel">
                    Bu dokumanı onaylayarak 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“Kanun”) 5. Maddesi kapsamında Şirketimize iletmiş olduğunuz kişisel verilerinizin, bilgi vermek ve sizlerle iletişime geçmek, görüntü toplamak, istatistiki bilgi toplamak ve bu işlemlerin yapılması amacıyla verileri yurtiçi ve yurtdışında yerleşik üçüncü kişilere aktarmak maksadıyla Şirketimiz tarafından işlenmesine açıkça rıza göstermektesiniz. Kişisel verileriniz kanuni düzenlemelere uygun olarak ticari teamüllerde öngörülen sürelerde işlenecektir. Kanun kapsamında Veri Sorumlumuz; Kanun’daki tüm sorumluluk ve yükümlülüklerine uymayı kabul etmektedir.
                    <br/>
                    Kişisel Verilerinizin, Veri Sorumlusu şahsın/Şirketimizin belirleyeceği yurtiçi veya yurtdışında bulunan 3. kişilere işbu verilerin saklanması, depolanması ve açık rıza gösterilen amaçlarla işlenmesi için aktarılmasına açıkça onay vermektesiniz.
                    <br/>
                    Kanuni haklarınız kapsamında; dilediğiniz zaman Veri Sorumlusu Şirketimize başvurarak tarafınıza ait kişisel verilerin işlenip işlenmediğini öğrenme, işlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve bu verilerin amaca uygun kullanılıp kullanılmadığını öğrenme, kişisel verilerinin aktarıldığı üçüncü kişileri bilme, kişisel verilerindeki hataların düzeltilmesini ve eğer aktarım yapılmışsa ilgili üçüncü kişiden bu düzeltmenin istenmesini talep etme, kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde bu verilerin silinmesini, yok edilmesini ya da anonim hale getirilmesini isteme ve eğer aktarım yapılmışsa bu talebin aktarılan üçüncü kişiye iletilmesini isteme, işlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonuç çıkmasına itiraz etme hakkınız mevcuttur. 
                    <br/>
                    Şirketimiz tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuat hükümleri kapsamında yapılmış olan işbu bilgilendirme ve aydınlatma sonrasında kişisel verileriniz aşağıdaki açık muvafakatiniz ile toplanacaktır:
                    <br/>
                    Ford otomotiv Sanayi A.Ş. tarafından açıklanan 6698 sayılı Kişisel Verilerin Korunması Kanunu’na ilişkin aydınlatma metninin tamamını okudum, anladım ve Ford otomotiv Sanayi A.Ş. Verilerimi yukarıda belirtilen amaçlar çerçevesinde işlemesi konusunda bilgilendirildim. Bu kapsamda Kişisel Verilerimin Aydınlatma Metni’nde belirtilen hususlar dahilinde paylaşılmasına konu hakkında tereddüde yer vermeyecek şekilde aydınlatılmış ve bilgi sahibi olarak, açık rızamla onay veriyorum.
                </p>
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.reject()">{{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.REJECTION_BUTTON' | translate }}</button>
                <button class="btn btn-sm btn-success px-4" (click)="callbacks.accept()">{{ 'APP.MAIN.LOBBY.AGREEMENT_MODAL.AGREEMENT_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #checkAgreementModel let-callbacks="callbacks">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.TITLE' | translate }}</h5>
            </div>
            <div class="modal-body">
                {{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.STATEMENT' | translate }}
            </div>
            <div class="modal-footer d-block text-right py-2 px-3">
                <button class="btn btn-sm btn-dark px-4" (click)="callbacks.close()">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.CANCEL_BUTTON' | translate }}</button>
                <button class="btn btn-sm btn-danger px-4" (click)="callbacks.proceed()">{{ 'APP.MAIN.LOBBY.CHECK_AGREEMENT_MODAL.PROCEED_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>