import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';

import { environment } from 'environments/environment';

// Router import for navigate
import { Router } from '@angular/router';

// Service imports
import { AuthService } from '../../services/core/auth.service';
import { DbService } from '@services/core/db.service';
import { OpentokService } from '@services/core/opentok.service';
import { LogService } from '@services/core/log.service';
import { CollaborationService } from '@services/core/collaboration.service';
import { ArCollaborationService } from '@services/core/ar-collaboration.service';

import { FlashMessageService } from '@services/support/flash-message.service';
import { LoaderService } from '../../services/support/loader.service';
import { UtilityService } from '@services/support/utility.service';

// rxjs imports
import { combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';

import { CallService } from '@services/core/call.service';
import { RoomSessionService } from '@services/core/room-session.service';
import { ModalService } from '@services/support/modal.service';
import { MultilanguageService } from '@services/support/multilanguage.service';

import { Validators } from '@angular/forms';

import { Language } from '@models/Language';

import * as semver from 'semver';
import { TranslateService } from '@ngx-translate/core';
import { SessionExportService } from '@services/core/session-export.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild("sessionExportTemplate", { static: true }) private sessionExportTemplate: TemplateRef<any>;
  @ViewChild("archivePermissionModal", { static: true }) private archivePermissionModal: TemplateRef<any>;
  @ViewChild("notSupportedTemplate", { static: true }) private notSupportedTemplate: TemplateRef<any>;
  @ViewChild("joinTemplate", { static: true }) private joinTemplate: TemplateRef<any>;
  @ViewChild("whatsNewTemplate", { static: true }) private whatsNewTemplate: TemplateRef<any>;
  @ViewChild("noDeviceTemplate", {static: true}) private noDeviceTemplate: TemplateRef<any>;
  @ViewChild("sessionFilesTemplete", {static: true}) private sessionFilesTemplete: TemplateRef<any>;

  @ViewChild('videoElement') videoElement: ElementRef;

  // Authentication subscription - saved for unsubscribe
  private authSubscription: Subscription = null;
  private userSubscription: Subscription = null;
  private callSubscription: Subscription = null;
  
  exportNameChangedSource: Subject<string> = new Subject<string>();
  exportNameAuthor: string;
  isExportNameLocked: boolean = false;

  callFlashMessageId: number = null;

  isUserDataLoaded: boolean = false;
  showLogoutModal: boolean = false;

  connectedSub: Subscription = null;
  disconnectedMessageId: number = null;

  cameraMirror: boolean = false;
  private cameraMirrorSub: Subscription = null;

  addOnsSub: Subscription = null;
  addOns: { [key: string]: boolean } = { sessionexport: false };
  licenseMessageId: number = null;
  exportModalId: number = null;

  cards: Object[] = [];
  currentLang: Language;
  allLangs: Language[] = [];

  whatsNewSub: Subscription = null;
  webLastSeenVersionTmp: string;

  sessionExportModalId: number = null
  sessionExportSub: Subscription = null;

  openProfileRequest = new Subject<void>();

  validMailValidator = Validators.pattern(/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  invalidEmailError = {pattern: ""};
  invalidEmailMessageSub: Subscription = null;

  showCheckboxes = false;
  downloadSelectedFiles = false;
  showDownloadButton: boolean = false;

  constructor(
    private authService: AuthService,
    private callService: CallService,
    private roomSessionService: RoomSessionService,
    private dbService: DbService,
    private router: Router,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private utilityService: UtilityService,
    private flashMessageService: FlashMessageService,
    private multilanguageService: MultilanguageService,
    private translateService: TranslateService,
    private sessionExportService: SessionExportService,

    /*** THIS SERVICES INJECTED FOR BOOTSTRAP ***/
    private opentokService: OpentokService,
    private logService: LogService,
    private collaborationService: CollaborationService,
    private arCollaborationService: ArCollaborationService
  ) { }

  ngOnInit() {
    // Navigate login if user not authenticated
    this.authSubscription = this.authService.isAuthenticated().subscribe(authenticated => {
      if (!authenticated) {
        this.router.navigate(['/login']);
      }
    });

    this.authService.getLastWebsiteVersion()
    .then(currentLastVersion => {
      if (!this.authService.currentUser.web_last_seen_version) { 
        return this.authService.setLastSeenWebsiteVersion(currentLastVersion);
      }
    })
    .finally(() => this.onWhatsNewModal(false))

    this.whatsNewSub = this.utilityService.whatsNewRequest.subscribe(() => { this.onWhatsNewModal(true) });

    this.sessionExportSub = this.sessionExportService.sessionExportRequest.subscribe(([roomId, sessionId, sessionEnded]) => {
      this.onSessionExportModal(roomId, sessionId, sessionEnded)
    });

    this.callService.on("endCall", this.onEndCall);

    this.addOnsSub = this.authService.addOns.subscribe(addOns => { this.addOns = addOns; });

    this.loaderService.show();
    this.userSubscription = this.authService.user
    .subscribe(user => {
      if (!this.isUserDataLoaded && !!user) {
        this.loaderService.hide();
        this.isUserDataLoaded = true;
      }
    });

    this.invalidEmailMessageSub = this.translateService.get('APP.MAIN.EMAIL_RECIPIENTS.INVALID_EMAIL').subscribe(tr => {
      this.invalidEmailError.pattern = tr;
    });

    this.cameraMirrorSub = this.opentokService.cameraMirror.subscribe(mirror => {
      this.cameraMirror = mirror;
    });

    this.callSubscription = this.callService.incomingCall
    .subscribe(dbCall => this.showHideCallFlash(dbCall));

    this.connectedSub = this.dbService.isConnected.subscribe(connected => {
      if (connected) {
        if (this.disconnectedMessageId) {
          this.flashMessageService.hide(this.disconnectedMessageId);
        }
      } else {
        this.flashMessageService.showTranslated('APP.SHARED.INTERNET_OFFLINE', { noTimeout: true, showCloseBtn: true })
        .then(id => { this.disconnectedMessageId = id });
      }
    });

    Promise.all([
      this.authService.user.pipe(filter(u => u !== null), first()).toPromise(),
      this.authService.license.pipe(first()).toPromise(),
      this.utilityService.getServerTimeOffset()
    ])
    .then(([user, license, offset]) => {
      const now = Date.now();
      if (license.end > now) {
        if (user.role === 'admin') {
          const days = Math.ceil((license.end - now + offset) / 86400000);
          const alertType = days > 15 ? 'alert-warning' : 'alert-danger';
  
          if (license.demo) {
            this.flashMessageService.showTranslatedWithData('APP.MAIN.DEMO_LEFT', { appName: environment.design.appName, days: days }, { cssClass: alertType, noTimeout: true, showCloseBtn: true })
            .then(id => { this.licenseMessageId = id });
          } else if (days < 30) {
            this.flashMessageService.showTranslatedWithData('APP.MAIN.LICENSE_LEFT', { appName: environment.design.appName, days: days }, { cssClass: alertType, noTimeout: true, showCloseBtn: true })
            .then(id => { this.licenseMessageId = id });
          }
        }
      }
    }).finally(() => {
      if(this.authService.currentUser.default_room) {
        this.router.navigate(['/rooms'])
      }
    });
  }

  cameraMirrorChanged(mirror: boolean) {
    this.opentokService.changeCameraMirror(mirror);
  }

  onWhatsNewModal(directlyOpen: boolean) {
    this.authService.getWebUpdates()
    .then(websiteUpdates => {
      const updateList = websiteUpdates ? websiteUpdates.versions : null;
      const currentLastVersion = websiteUpdates ? websiteUpdates.currentLastVersion : null;

      if (updateList && currentLastVersion) {
        let maxValue: number;
        if (Object.keys(updateList).length > 3) {
          maxValue = 3;
        } else {
          maxValue = Object.keys(updateList).length;
        }
        const updObj = {};
        for (let i=0; i<maxValue; i++) {
          updObj[`${i}`] = updateList[Object.keys(updateList)[i]];
        }
        
        const currentLang = this.multilanguageService.currentLang; 
        const webLastSeenVersion = this.authService.currentUser.web_last_seen_version;
        if (directlyOpen) {
          this.cards = this.getUnseenCards(currentLang, updObj, currentLastVersion, this.webLastSeenVersionTmp, true);
          this.openWhatsnewModal(null, null);
        } else {
          this.cards = this.getUnseenCards(currentLang, updObj, currentLastVersion, webLastSeenVersion, false);
          this.openWhatsnewModal(webLastSeenVersion, currentLastVersion);
        }
      }
      else if (directlyOpen === true) {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.WHATSNEW.NO_UNREAD_UPDATE', { cssClass: "alert-warning" });
        return;
      }
    });
  }

  getUnseenCards(currentLang: Language, updateList: Object, currentLastVersion: string, webLastSeenVersion: string, directlyOpen: boolean): Array<Object> {
    let cards = [];
    const envName = environment.design.environmentName;
    const curlang = currentLang.code;
    const updateIds = updateList ? Object.keys(updateList) : [];
    
    if (directlyOpen) {
      updateIds.forEach(unseenUpdateId => {
        let unseenUpdatesDetails = updateList[unseenUpdateId];
        let cardwEnv = unseenUpdatesDetails[envName];
        
        let cardwEnvLang;
        if (!cardwEnv[curlang]) {
          cardwEnvLang = cardwEnv["en"];
        } else {
          cardwEnvLang = cardwEnv[curlang];
        }
        
        Object.keys(cardwEnvLang).forEach(id => {
          cards.push({
            ...Object.values(cardwEnvLang[id])
          });
        });
      });

      for (let i=0; i<cards.length; i++) { cards[i] = { desc: cards[i][0], image: cards[i][1], title: cards[i][2]} }
      return cards;
    } 
    else {
      let isWebsiteUpdated = semver.gt(currentLastVersion, webLastSeenVersion);
      if (isWebsiteUpdated) {
        const unseenUpdatesIds = updateIds.filter(upId => semver.lte(updateList[upId].v, currentLastVersion) && semver.gt(updateList[upId].v, webLastSeenVersion));

        unseenUpdatesIds.forEach(unseenUpdateId => { 
          let unseenUpdatesDetails = updateList[unseenUpdateId];
          let cardwEnv = unseenUpdatesDetails[envName];

          let cardwEnvLang;
          if (!cardwEnv[curlang]) {
            cardwEnvLang = cardwEnv["en"];
          } else {
            cardwEnvLang = cardwEnv[curlang];
          }

          Object.keys(cardwEnvLang).forEach(id => {
            cards.push({
              ...Object.values(cardwEnvLang[id])
            });
          });
        });

        for (let i=0; i<cards.length; i++) { cards[i] = { desc: cards[i][0], image: cards[i][1], title: cards[i][2]} }
        return cards;
      }
    }
  }

  openWhatsnewModal(webLastSeenVersion: string, currentLastVersion: string) {
    this.webLastSeenVersionTmp = webLastSeenVersion;
    let environmentVersion = environment.version.toString();
    let isModalOpened = false;

    if (!webLastSeenVersion && !currentLastVersion) {
      if (this.cards && this.cards.length > 0) {
        const modalId = this.modalService.show({
          template: this.whatsNewTemplate,
          context: {
            dataModel: null,
            callbacks: {
              close: async () => {
                this.modalService.hide(modalId);
                isModalOpened = true;
              }
            }
          }
        });
      }
    } 
    else {
      let isLatestVersionSeen = semver.eq(currentLastVersion, webLastSeenVersion);
      if (!isLatestVersionSeen) {                                     
        let isUserOnline = semver.gt(currentLastVersion, environmentVersion);
        if (isUserOnline) {                 
          if (this.cards && this.cards.length > 0) {
            const modalId = this.modalService.show({
              template: this.whatsNewTemplate,
              context: {
                dataModel: null,
                callbacks: {
                  close: () => {
                    this.modalService.hide(modalId);
                    this.authService.setLastSeenWebsiteVersion(currentLastVersion)
                    .then(() => {
                      var refresh = window.sessionStorage.getItem('refresh');
                      if (refresh === null) {
                        document.location.reload();
                        window.sessionStorage.setItem('refresh', "1");
                      }
                    });
                    isModalOpened = true;
                  }
                }
              }
            });
          }
        } 
        else {           
          if (this.cards && this.cards.length > 0) {                                              
            const modalId = this.modalService.show({
              template: this.whatsNewTemplate,
              context: {
                dataModel: null,
                callbacks: {
                  close: async () => {
                    this.modalService.hide(modalId);
                    await this.authService.setLastSeenWebsiteVersion(currentLastVersion);
                    isModalOpened = true;
                  }
                }
              }
            });
          }
        } 
      } 
    }
    return isModalOpened;   
  }

  onExportNameChange(text: string): void {
    this.exportNameChangedSource.next(text);
  }

  getExportTimePart(timestamp: number, timezone: string) {
    const d = new Date(timestamp+UtilityService.timezoneOffset+UtilityService.timezones[timezone]);
    return "_" + d.getFullYear() + "-" +
      ("0"+(d.getMonth()+1)).slice(-2) + "-" +
      ("0" + d.getDate()).slice(-2) + "_" +
      ("0" + d.getHours()).slice(-2) + "-" +
      ("0" + d.getMinutes()).slice(-2) + "-" +
      ("0" + d.getSeconds()).slice(-2) + "_" +
      timezone;
  }

  onSessionFilesModal(roomId: string, sessionId: string, sessionEnded?: boolean) {
    this.roomSessionService.getSession(sessionId).pipe(first()).toPromise().then(session => {
      const modalId = this.modalService.show({
        template: this.sessionFilesTemplete,
        context: {
          dataModel: {
            sessionId: sessionId,
            session: session
          },
          callbacks: {
            close: () => {
              this.modalService.hide(modalId);
            },
            toggleSelection: () => { 
              this.showCheckboxes = !this.showCheckboxes;
              this.showDownloadButton = !this.showDownloadButton;
            },
            onDownloadStarted: ()  => {
              this.downloadSelectedFiles = !this.downloadSelectedFiles;
            }         
          }
        }
      })
    })
  }

  onSessionExportModal(roomId: string, sessionId: string, sessionEnded?: boolean) {
    let dataModel = { 
      emailRecipients: [],
      ad_user: this.authService.currentUser.ad_user,
      exportName: "SessionExport"
    };

    let sub = this.authService.user.pipe(
      map(u => u && u.email ? u.email : null),
      distinctUntilChanged()
    ).subscribe(email => {
      const defaultIndex = dataModel.emailRecipients.findIndex(e => e.default);
      if (email) {
        if (defaultIndex > -1) {
          dataModel.emailRecipients.splice(defaultIndex, 1, { value: email, display: email, default: true });
        } else {
          dataModel.emailRecipients.push({ value: email, display: email, default: true });
        }
      } else {
        if (defaultIndex > -1) {
          dataModel.emailRecipients.splice(defaultIndex, 1);
        }
      }
    });

    let sub2 = combineLatest([this.authService.timezone, this.roomSessionService.getSessionData(roomId, sessionId)])
    .subscribe(([timezone, sessionData]) => {
      if (sessionData.export_name?.name || sessionData.export_name?.name === "") {
        dataModel.exportName = sessionData.export_name?.name;
      } else {
        const dateString = this.getExportTimePart(sessionData.create_time, timezone);
        dataModel.exportName = environment.design.appName.replace(/ /g, "_").concat(dateString);
      }
      this.exportNameAuthor = sessionData.export_name?.author;
      this.isExportNameLocked = sessionData.export_name?.locked;
    });

    let exportNameChangedSub = this.exportNameChangedSource.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(text => {
        if (text) {
          this.roomSessionService.setExportName(roomId, sessionId, text);
        }

    });

    this.sessionExportModalId = this.modalService.show({
      template: this.sessionExportTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => {
            this.modalService.hide(this.sessionExportModalId);
            this.sessionExportModalId = null;
            if (sub) { sub.unsubscribe() }
            if (sub2) { sub2.unsubscribe() }
            if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
          },
          getexport: () => {
            this.loaderService.show();
              this.roomSessionService.getSessionExport(roomId, sessionId, dataModel.emailRecipients.map(e => e.value))
              .then(() => {
                this.modalService.hide(this.sessionExportModalId);
                this.sessionExportModalId = null;
                if (sub) { sub.unsubscribe() }
                if (sub2) { sub2.unsubscribe() }
                if (exportNameChangedSub) { exportNameChangedSub.unsubscribe() }
                if (sessionEnded) {
                  this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.ENDED_SESSION_EXPORT_SUCCEEDED", { cssClass: "alert-info" });
                } else {
                  this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.EXPORT_SUCCEEDED", { cssClass: "alert-info" });
                }
              })
              .catch(error => this.flashMessageService.showTranslated("APP.MAIN.GET_EXPORT_MODAL.EXPORT_FAILED"))
              .finally(() => this.loaderService.hide());
          }
        }
      }
    });
    this.callService.setSessionExportModalId(this.sessionExportModalId);
  }

  showHideCallFlash(dbCall: any) {
    if (dbCall) {
      if (!this.callFlashMessageId) {
        this.callService.callReceived(dbCall).catch(err => console.log("CANNOT SET CALL RECEIVED"));

        this.flashMessageService.showTranslatedWithData("APP.MAIN.CALL_MESSAGE.TITLE", dbCall,
          {
            cssClass: "alert-success", noTimeout: true, closeOnClick: false, insertHtml: true,
            buttons: [
              {title: "APP.MAIN.CALL_MESSAGE.DENY", cssClass: "btn-dark", callback: () => {
                this.flashMessageService.hide(this.callFlashMessageId);
                this.callFlashMessageId = null;

                this.loaderService.show();
                this.callService.denyCall(dbCall)
                .catch(error => this.showHideCallFlash(dbCall))
                .finally(() => this.loaderService.hide());
              }},
              {title: "APP.MAIN.CALL_MESSAGE.ACCEPT", cssClass: "btn-success", callback: () => {
                if (this.router.isActive('/', true)) {
                  this.onAnswerCall();
                } else {

                  this.router.navigate(['/'])
                  .then(success => {
                    if (success) { this.onAnswerCall() }
                  });
                }
              }}
            ]
          }
        ).then(id => { this.callFlashMessageId = id; });
      }
    } else {
      if (this.callFlashMessageId) {
        this.flashMessageService.hide(this.callFlashMessageId);
        this.callFlashMessageId = null;
      }
    }
  }

  async showJoinModal() {
    const callData = await this.callService.incomingCall.pipe(first()).toPromise();
    if (!callData) {
      this.flashMessageService.show("Call missed.");
      return;
    }

    // @ts-ignore
    const AContext = window.AudioContext || window.webkitAudioContext;
    const model = {
      publishResolutions: ["320x180", "320x240", "640x360", "640x480", "1280x720", "1280x960"],
      currentStream: null,
      audios: [],
      videos: [],
      selectedVideo: null,
      selectedAudio: null,
      audioEnabled: true,
      videoEnabled: false,
      //@ts-ignore
      audioContext: new AContext(),
      audioLevel: 0,
      audioLevelSub: null,
      settingsOn: false,
      callData: callData,
      status: "waiting",
      enableJoin: false,
      enableCancel: false
    };
    const modalId = this.modalService.show({
      template: this.joinTemplate,
      context: {
        dataModel: model,
        callbacks: {
          toggleAudio: () => this.opentokService.togglePreviewAudio(model),
          toggleVideo: () => this.opentokService.togglePreviewVideo(model),
          changeAudioSource: (device: OT.Device) => this.opentokService.changePreviewSource("audio", model, this.videoElement),
          changeVideoSource: (device: OT.Device) => this.opentokService.changePreviewSource("video", model, this.videoElement),
          close: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            this.modalService.hide(modalId);

            this.showHideCallFlash(callData);
          },
          join: () => {
            this.opentokService.destroyPreview(model, this.videoElement);
            if (model.status === 'no-devices-found') {
              this.showNoDeviceModal(modalId, callData);
            } else {
              this.modalService.hide(modalId);
              this.answerCall(callData, false);
            }
          }
        }
      }
    });

    setTimeout(() => {
      this.opentokService.startPreview(model, this.videoElement);
    }, 1000);
  }

  showNoDeviceModal(joinModalId: number, callData: any) {
    const modalId = this.modalService.show({
      template: this.noDeviceTemplate,
      context: {
        dataModel: null,
        callbacks: {
          cancel: () => {
            this.modalService.hide(modalId);
          },
          join: () => {
            this.modalService.hide(modalId);
            this.modalService.hide(joinModalId);
            this.answerCall(callData, false);
          }
        }
      }
    });
  }

  onAnswerCall() {
    if (this.callFlashMessageId) {
      this.flashMessageService.hide(this.callFlashMessageId);
      this.callFlashMessageId = null;
    }

    const parser = this.utilityService.getBrowserParser();
    const isTouchDevice = 'ontouchstart' in window;
    // Is Device iOS or ipadOS (detected as touch macOS)
    const isIOS = parser.is("iOS");
    const isIpad = parser.is("macOS") && isTouchDevice;

    if (isIOS || isIpad) {
      if (!parser.is("Safari")) {
        this.showNotSupportedModal({ ios: true });
        return;
      } else if (!parser.satisfies({safari: ">=12"})) {
        this.showNotSupportedModal({ ios: true, ios_version_low: true });
        return;
      }
    }
    if (parser.is("Microsoft Edge") && !parser.satisfies({edge: '>=79' })) {
      this.showNotSupportedModal({ ios: false });
      return;
    }

    if (!(environment.design.supportedBrowserList.some(browserName => parser.is(browserName)) && this.utilityService.checkOpentokRequirements())) {
      this.showNotSupportedModal({ ios: false });
      return;
    }
    this.showJoinModal();
  }

  showNotSupportedModal(dataModel: any) {
    const modalId = this.modalService.show({
      template: this.notSupportedTemplate,
      context: {
        dataModel: dataModel,
        callbacks: {
          close: () => this.modalService.hide(modalId)
        }
      }
    });
  }

  async answerCall(callData: any, archiveAllowed: boolean) {
    this.loaderService.show();
    this.callService.joinRoom(callData.room_id, archiveAllowed)
    .then(() => this.router.navigate(['/room']))
    .catch(error => {
      if (error.message === 'concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_CONCURRENT_ERROR', { timeout: 10000 });
      } else if (error.message === 'expert-concurrent-limit-reached') {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_EXPERT_CONCURRENT_ERROR', { timeout: 10000 });
      } else if (error.message === 'archive-permission-needed') {
        this.showArchivePermissionModal(callData);
      } else {
        this.flashMessageService.showTranslated('APP.MAIN.LOBBY.JOIN_UNKNOwN_ERROR');
      }
    })
    .finally(() => this.loaderService.hide());
  }

  showArchivePermissionModal(callData: any) {
    const modalId = this.modalService.show({
      template: this.archivePermissionModal,
      context: {
        dataModel: null,
        callbacks: {
          deny: () => this.modalService.hide(modalId),
          allow: () => {
            this.answerCall(callData, true);
            this.modalService.hide(modalId);
          }
        }
      }
    });
  }

  onEndCall = (roomId: string, sessionId: string, kickRoomStatus: string, reconnectionRequired: boolean,  sessionEnded: boolean) => {
    if (this.authService.currentUser.role === "expert" && !reconnectionRequired) {
      if (this.addOns.sessionfilesaftersession && !this.addOns.webdav) {
        this.onSessionFilesModal(roomId, sessionId, sessionEnded);
      } else if (this.addOns.sessionexport) {
        this.onSessionExportModal(roomId, sessionId, sessionEnded);
      }
    }

    if (window.top) {
      window.top.postMessage({ closed: true }, "*")
    }
  }

  ngOnDestroy() {
    this.callService.off("endCall", this.onEndCall);

    // Stop subscribing on destroy - prevent memory leaks
    if (this.authSubscription) { this.authSubscription.unsubscribe(); }
    if (this.userSubscription) { this.userSubscription.unsubscribe(); }
    if (this.connectedSub) { this.connectedSub.unsubscribe(); }
    if (this.callSubscription) { this.callSubscription.unsubscribe(); }
    if (this.whatsNewSub) { this.whatsNewSub.unsubscribe(); }
    if (this.invalidEmailMessageSub) { this.invalidEmailMessageSub.unsubscribe(); }
    if (this.sessionExportSub) { this.sessionExportSub.unsubscribe(); }
    if (this.sessionExportModalId) { this.modalService.hide(this.sessionExportModalId) }
    if (this.cameraMirrorSub) { this.cameraMirrorSub.unsubscribe() }
    if (this.addOnsSub) { this.addOnsSub.unsubscribe() }

    if (this.licenseMessageId) { this.flashMessageService.hide(this.licenseMessageId) }
    if (this.exportModalId) { this.modalService.hide(this.exportModalId) }
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (this.authService.isAddOnAvailable("webstatus") && document.visibilityState === "visible" && this.authService.currentUser && !this.authService.currentUser.status?.web_status_lock) {
      this.authService.setUserStatus("available", false)
    }
  }
}
